import React, { useState } from "react";
import PropTypes from "prop-types";

function Textarea({
  color,
  // onClick,
  className,
  forwardRef,
  title,
  id,
  name,
  onChange,
  value,
  placeholder,
  rows,
  cols,
  maxLength,
  icon,
}) {
  let classList = [
    "shared__input",
    "shared__textarea",
    `shared__input--${color}`,
  ];
  const [fallbackValue, setFallbackValue] = useState(value);

  classList = classList.concat(
    Array.isArray(className) ? className : className.split(" "),
  );
  const charsLeft = maxLength
    ? maxLength - (value || fallbackValue).length
    : null;
  const Icon = icon;
  return (
    <div
      className={`shared__input-wrapper ${
        icon ? "shared__input-wrapper--icon" : ""
      }`}>
      <label
        htmlFor={id}
        className={`shared__input-label shared__input-label--${color} ${
          value || fallbackValue ? "shared__input-label--show" : ""
        }`}>
        {title}
      </label>
      <textarea
        id={id}
        name={name}
        placeholder={placeholder}
        rows={rows}
        cols={cols}
        ref={forwardRef}
        value={onChange ? value : fallbackValue}
        className={`${classList.join(" ")} ${
          value || fallbackValue ? "" : "shared__input--init"
        } ${maxLength ? "shared__input--sp" : ""} ${
          icon ? "shared__input--icon" : ""
        }`}
        onChange={onChange || ((e) => setFallbackValue(e.target.value))}
      />
      {icon ? <Icon className="shared__icon--textarea" width={24} /> : null}
      {maxLength ? (
        <span
          className={`shared__input-max ${
            charsLeft < 0 ? "shared__input-max--error" : ""
          }`}>
          {charsLeft} characters remaining...
        </span>
      ) : null}
    </div>
  );
}

Textarea.designSystemProps = {
  color: {
    type: "string",
    description: "Textarea border color.",
    default: "defaultText",
    propType: PropTypes.string,
    required: false,
  },
  className: {
    type: ["string", "array"],
    description: "List of classes for this element.",
    required: false,
    default: "",
    propType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  },
  forwardRef: {
    type: ["function", "object"],
    description: "Ref to component or DOM element.",
    subProps: {
      current: {
        type: ["any"],
        description: "DOM node of Ref object",
        required: false,
        default: null,
      },
    },
    required: false,
    default: null,
    propType: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.any }),
    ]),
  },
  id: {
    type: "string",
    description: "Unique identifier for this element.",
    required: true,
    propType: PropTypes.string.isRequired,
  },
  name: {
    type: "string",
    propType: PropTypes.string.isRequired,
    description: "Name of textarea element.",
    required: true,
  },
  title: {
    propType: PropTypes.string,
    description: "Title of textarea label.",
    required: false,
    default: null,
  },
  placeholder: {
    type: "string",
    propType: PropTypes.string,
    description: "Placeholder text if textarea is empty.",
    required: false,
    default: "",
  },
  onChange: {
    type: "func",
    propType: PropTypes.func,
    description: "Function that executes when textarea changes.",
    required: false,
    default: null,
  },
  value: {
    type: ["string", "number"],
    propType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    description: "Initial value of textarea.",
    required: false,
    default: "",
  },
  maxLength: {
    type: "number",
    propType: PropTypes.number,
    description: "Max number of characters allowed for textarea.",
    required: false,
    default: 0,
  },
  icon: {
    type: "element",
    propType: PropTypes.elementType,
    description: "Icon included in textarea box.",
    required: false,
    default: null,
  },
  rows: {
    type: "number",
    propType: PropTypes.number,
    description: "Default number of rows to display.",
    required: false,
    default: 3,
  },
  cols: {
    type: "number",
    propTypes: PropTypes.number,
    description: "Default number of columns to display.",
    required: false,
    default: null,
  },
};

const propTypes = {};
const defaultProps = {};
Object.entries(Textarea.designSystemProps).map(([k, v]) => {
  if (v.propType) {
    propTypes[k] = v.propType;
  }
  if (v.default || typeof v.default !== "undefined") {
    defaultProps[k] = v.default;
  }
  return false;
});
Textarea.propTypes = { ...propTypes };
Textarea.defaultProps = { ...defaultProps };

Textarea.displayName = "Textarea";

export default Textarea;

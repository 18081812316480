import React from "react";
// import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import DesignProps from "../components/molecules/design-props";
import Textarea from "../components/atoms/textarea";
import AlarmOnIcon from "../components/atoms/icons/alarm-on";

function DesignTextarea() {
  return (
    <div className="design__content">
      <SEO title="Design System - Textarea" />
      <h2>Textarea</h2>

      <div className="design__stories">
        <div className="design__story-section">
          {/* <h5>Textarea</h5> */}
          <DesignStory
            title="Text Box"
            type="component"
            component={Textarea}
            compact
            props={{
              placeholder: "Type text here...",
              title: "Text Box",
              name: "textBox",
              id: "designTextBox",
              color: "green",
              rows: 8,
            }}
          />
          <DesignStory
            title="Text with Icon"
            type="component"
            component={Textarea}
            compact
            props={{
              placeholder: "Type text here...",
              title: "Text Icon Box",
              name: "textIconMaxBox",
              id: "designTextIconMaxBox",
              color: "green",
              rows: 8,
              icon: AlarmOnIcon,
            }}
          />
          <DesignStory
            title="Max Length"
            type="component"
            component={Textarea}
            compact
            props={{
              placeholder: "Type text here...",
              title: "Text Box",
              name: "textMaxBox",
              id: "designTextMaxBox",
              color: "green",
              rows: 8,
              maxLength: 200,
            }}
          />
        </div>

        <div className="design__story-section">
          <DesignProps component={Textarea} title="Textarea Properties" />
        </div>
      </div>
    </div>
  );
}

export default DesignTextarea;
